import React from "react"
import Layout from "../components/layout"
import Item from "../components/item"
import SEO from "../components/seo"
import ERROR from "../components/error"
import axios from "axios"
import Load from "../components/loading"
import Style from "../styles/main.module.css"
import FriendLink from "../components/friendlink"

const Imgs = (props) => (
    <div className={Style.box}>
    {
        props.imgs.map((item, index) => (
            <Item
                key={index}
                itemId={item.id}
                itemTitle={item.title}
                itemSrc={item.imgs[0]}
                itemLen={item.imgs.length}
                isLast={index === props.imgs.length - 1 && (props.imgs.length % 24 === 0)}
                nextImgs={props.nextImgs}
            />
        ))
    }
    </div>
)

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.instance = axios.create({
            baseURL: '/page-data/pic/',
            time: 5000,
        });
        this.ids = [];
        this.state = {
            list: [],
            error: false
        }
    }

    createIds(sum, exclude) {
        let ids = [];
        for(let i = 1; i <= sum; i++) {
            if(!exclude.includes(i)) {
                ids.push(i);
            }
        }
        return ids;
    }

    getNums() {
        let s = Math.floor(Math.random() * this.ids.length);
        let m = this.ids[s];
        this.ids.splice(s,1);
        return m;
    }

    getLst(num) {
        let i = 0
        let list = [];
        while(i < num) {
            list.push(this.getNums());
            i++;
        }
        return list;
    }

    insertLst(arr) {
        let items = this.state.list;
        arr.forEach(element => {
            this.instance.get(`${element}/page-data.json`) 
            .then((res) => {
                let data = res.data.result.pageContext.item
                items.push(data);
                this.setState({
                    list: items
                })
            })
        });
    }

    componentDidMount() {
        this.instance.get('1/page-data.json')
        .then((response) => {
            let list = response.data.result.pageContext.list
            let sum = list.sum;
            let exclude = list.exclude;
            this.ids = this.createIds(sum, exclude);
            let lst = this.getLst(24);
            this.insertLst(lst);
        })
        .catch((error) => {
            this.setState({
                error: true
            })
        })
    }

    nextImgs() {
        if(this.state.list.length < 24){
            let list = this.getLst(24);
            this.insertLst(list);
        }
    }

    resetImgs() {
        this.setState({
            list: []
        },
        () => {
            let list = this.getLst(24);
            this.insertLst(list);
        })
    }
    
    render() {
        return (
            <Layout>
                <SEO title="图片列表" />
                {
                    this.state.error ? <ERROR /> : <Imgs imgs={this.state.list} nextImgs={() => this.nextImgs()}/>
                }
                {
                    this.state.list.length < 24 ? <Load /> : <button className={Style.btn} onClick={() => this.resetImgs()}>换一批</button>

                }
                <button className={Style.goTop} onClick={() => (document.body.scrollTop = document.documentElement.scrollTop = 0)}>&nbsp;</button>
                <a href="/" className={Style.goHome}>&nbsp;</a>
                <FriendLink urlType="index" />
            </Layout>
        )
    }
}

export default Index

