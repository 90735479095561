import React from "react"
import Style from "../styles/loading.module.css"
export default () => (
    <div style={{width:`100vw`}}>
    <div className={Style.preloader}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    </div>
)