import React from "react"
import Style from "../styles/404.module.css"
export default (props) => (
<div className={Style.main}>
<h3>{props.itemTitle ? props.itemTitle: '网络繁忙！'}</h3>
    <p>
    {props.itemInfo ? props.itemInfo:'你的网络好像不太好的样子请稍后再试~'}<br/>
<a href="/">{props.itemNote ? props.itemNote:'刷新试试'}</a>
    </p>
</div>
)